import React from "react";
import { withStyles } from "@material-ui/core";
import SubPageBase from "../components/SubPageBase";
import LocationMap from "../components/LocationMap";
import nerelerdeyizImg from "../images/nerelerdeyiz.jpg";

const styles = (theme) => ({});

const NerelerdeyizPage = () => (
  <SubPageBase title="NERELERDEYİZ?" img={nerelerdeyizImg}>
    <LocationMap />
  </SubPageBase>
);

export default withStyles(styles)(NerelerdeyizPage);
