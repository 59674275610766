import { compose, withProps } from "recompose";
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";
import * as React from "react";
import { useState } from "react";
import { colorPalette } from "./colors";
import { graphql, useStaticQuery } from "gatsby";
import { filter, union } from "lodash";
import makeStyles from "@material-ui/core/styles/makeStyles";
import markerImg from "../images/marker.png";
import magazaImg from "../images/services/magaza.png";
import uretimImg from "../images/services/uretim.png";
import depoImg from "../images/services/depo.png";
import magazaBgImg from "../images/dc_magaza.jpg";

const styles = require("../images/map-style");

const MapWithAMarkerClusterer = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyARFehoJX8lhEwI3czIrEeSArsXgN-VUsE&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    center={props.selectedLocation ? props.selectedLocation : { lat: 39.344398, lng: 35.2851033 }}
    zoom={props.selectedLocation ? 14 : 6.4}
    clickableIcons={false}
    options={{
      disableDefaultUI: true,
      styles: styles,
    }}
  >
    {props.markers.map((marker) => (
      <Marker
        key={marker.location_id}
        position={{ lat: marker.lat, lng: marker.lng }}
        icon={{
          url: markerImg,
          size: { width: 60, height: 100 },
          anchor: { x: 0, y: 50 },
          scaledSize: { width: 40, height: 60 },
        }}
        onClick={() => props.onSelect(marker)}
      />
    ))}
  </GoogleMap>
));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    height: "80vh",
  },
  mapView: {
    height: "100%",
    flexGrow: 1,
    background: colorPalette.blue,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  listView: {
    width: "400px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const LocationMap = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      allLocations {
        nodes {
          location_id
          lat
          lng
          name
          city
          address
          phone
          type
        }
      }
    }
  `);

  const allLocations = data.allLocations.nodes;
  const [locations, setLocations] = useState(allLocations);
  const [selected, setSelected] = useState();
  const [filterValue, setFilterValue] = useState("");
  const [typeFilters, setTypeFilters] = useState(["ST", "WR", "PR", "OF"]);

  const makeFilter = (value, typeFilters) => {
    value = value.toUpperCase();
    setFilterValue(value);
    if (value.length === 0) {
      setLocations(filter(allLocations, (l) => typeFilters.includes(l.type)));
    } else {
      setLocations(
        filter(
          allLocations,
          (l) =>
            typeFilters.includes(l.type) &&
            ((l.city && l.city.toUpperCase().indexOf(value) > -1) || l.name.indexOf(value) > -1)
        )
      );
    }
  };

  const filterTypes = (selected, type) => {
    const types = selected ? union(typeFilters, [type]) : filter(typeFilters, (t) => t !== type);
    setTypeFilters(types);
    makeFilter(filterValue, types);
  };

  return (
    <div className={classes.root}>
      <div className={classes.mapView}>
        <MapWithAMarkerClusterer
          markers={locations}
          selectedLocation={selected}
          onSelect={(location) => {
            setSelected(location);
          }}
        />
      </div>
      <div className={classes.listView}>
        <input
          placeholder="Şehir ismi girin"
          style={{
            padding: "20px",
            margin: "20px",
            fontSize: "1rem",
            background: colorPalette.grey,
            border: "none",
          }}
          value={filterValue}
          onChange={(e) => makeFilter(e.target.value, typeFilters)}
        />
        <div
          style={{
            display: "flex",
            margin: "20px",
            marginTop: 0,
            minHeight: "min-content",
          }}
        >
          <FilterImage
            selected={typeFilters.includes("ST")}
            setSelected={(selected) => filterTypes(selected, "ST")}
            color={colorPalette.blue}
            imgSrc={magazaImg}
          />
          <FilterImage
            selected={typeFilters.includes("PR")}
            setSelected={(selected) => filterTypes(selected, "PR")}
            color={colorPalette.red}
            imgSrc={uretimImg}
          />
          <FilterImage
            selected={typeFilters.includes("WR")}
            setSelected={(selected) => filterTypes(selected, "WR")}
            color={colorPalette.orange}
            imgSrc={depoImg}
          />
        </div>
        <div
          style={{
            overflow: "scroll",
          }}
        >
          {locations.map((location, index) => {
            const inputRef = React.createRef();
            return (
              <LocationCard
                key={location.location_id}
                order={index + 1}
                location={location}
                selected={location === selected}
                ref={inputRef}
                onClick={() => {
                  const active = location === selected;
                  setSelected(active ? undefined : location);
                  if (active && !!inputRef.current) {
                    inputRef.current.scrollIntoView();
                  }
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

function LocationCard({ order, location, selected, ref, onClick }) {
  const { name, city, address, phone } = location;
  return (
    <div
      ref={ref}
      style={{
        backgroundImage: `url(${magazaBgImg})`,
        backgroundSize: "cover",
        boxShadow: `inset 0 0 0 2000px ${selected ? colorPalette.orangeWithAlpha("CC") : "#0A0A0ABB"}`,
        // boxShadow: `5 5 0 25 ${selected ? colorPalette.orangeWithAlpha('CC') : '#626c7140'}`,
        padding: "16px",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <div
        style={{
          color: selected ? "#fff" : colorPalette.orange,
          lineHeight: "1.6rem",
          fontWeight: 700,
          borderBottom: "2px solid",
          fontSize: "1.6rem",
          display: "inline",
        }}
      >
        {`00${order}`.substr(order.toString().length)}&nbsp;&nbsp;&nbsp;
      </div>
      <div
        style={{
          color: selected ? "#40495B" : "#fff",
          fontWeight: 700,
          fontSize: "1.2rem",
          marginTop: "16px",
        }}
      >
        {name}
        <sub
          style={{
            display: "block",
          }}
        >
          {city}
        </sub>
      </div>
      {selected ? (
        <>
          <div
            style={{
              marginTop: "16x",
              fontWeight: 600,
              color: "#40495B",
            }}
          >
            ADRES
            <sub
              style={{
                display: "block",
              }}
            >
              {address}
            </sub>
          </div>
          <div
            style={{
              marginTop: "16px",
              fontWeight: 600,
              color: "#40495B",
            }}
          >
            TELEFON
            <sub
              style={{
                display: "block",
              }}
            >
              {phone}
            </sub>
          </div>
        </>
      ) : null}
    </div>
  );
}

const FilterImage = ({ color, imgSrc, selected, setSelected }) => {
  return (
    <div
      style={{
        background: selected ? color : "#D1D2D9",
        borderRadius: "70px",
        marginRight: "20px",
        height: "70px",
        width: "70px",
        cursor: "pointer",
      }}
      onClick={() => setSelected(!selected)}
    >
      <img
        alt=""
        src={imgSrc}
        style={{
          height: "100%",
          width: "100%",
          filter: "brightness(0) invert(1)",
        }}
      />
    </div>
  );
};

export default LocationMap;
